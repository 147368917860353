import { navigate, graphql } from 'gatsby'
import React from 'react'
import { useLocation, getStoredCity } from '@ecommerce/shared'
import ConfirmationPage from '../../components/SignUp/ConfirmationPage'
import { extractPageData } from '../../components/SignUp/utils'
import { SignupPage } from '../../types/PgPages'

const SignupSucess = ({ location, data }: SignupPage) => {
  const {
    state: { cities },
  } = useLocation()
  const cityId = new URLSearchParams(location.search).get('cityId') ?? getStoredCity()?.id?.toString()

  const getCitySlug = (path = '') => {
    if (cityId) {
      const currentCity = cities.find((city) => city.id === Number(cityId))

      if (currentCity) return `/${currentCity.slug}/${path}`
    }

    return '/'
  }

  const { pageData, template } = extractPageData(data)

  return (
    <ConfirmationPage
      pageTitle={pageData?.title ?? ''}
      bgImage={template?.backgroundImage?.file?.url ?? ''}
      icon={template?.icon?.file?.url ?? ''}
      title={template?.pageHeading ?? ''}
      text={template?.subtitle ?? ''}
      topButtonText="Inicia sesión"
      topButtonAction={() => navigate(getCitySlug('login'))}
      bottomButtonText="Ir al Inicio"
      bottomButtonAction={() => navigate(getCitySlug())}
    />
  )
}

export default SignupSucess

export const query = graphql`
  query SignupSuccessPage($signupSuccessId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $signupSuccessId } }) {
      edges {
        node {
          title
          description
          keywords
          template {
            ... on ContentfulTmSignup {
              contentful_id
              pageHeading
              subtitle
              infoText
              icon {
                file {
                  url
                }
              }
              backgroundImage {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
